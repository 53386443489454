<!-- 折叠面板 -->
<template>
  <div class="foldingPanelBox">
    <div
      @click="
        titleClick();
        openColp();
      "
      :class="{ bottomLine: show }"
      class="titleBox"
    >
      <div class="titleConBox">
        <slot name="title"></slot>
      </div>
      <div class="iconBox" :class="{ opIc: show }">
        <van-icon name="arrow" />
      </div>
    </div>
    <transition
      :name="transitionName"
      @before-enter="collapseBeforeEnter"
      @enter="collapseEnter"
      @after-enter="collapseAfterEnter"
      @before-leave="collapseBeforeLeave"
      @leave="collapseLeave"
      @after-leave="collapseAfterLeave"
    >
      <div v-show="show" class="conBodx">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
const props = defineProps({
  transitionName: {
    type: String,
    default: "collapse-transition",
  },
  // 自身的ref名字(多个使用时必传)
  oref: {
    type: String,
    default: "",
  },
  // 需要关闭的组件的ref(多个使用时必传)
  refList: {
    type: Array,
    default: [],
  },
});
const emit = defineEmits(["titleClock"]);
//------------------------ data -------------------------------------
const oldPaddingTop = ref("");
const oldPaddingBottom = ref("");
const oldOverflow = ref("");
const show = ref(false);

//------------------------ methods -------------------------------------
// 关闭需要关闭的下拉面板并打开这个下拉面板
const openColp = () => {
  for (let i = 0; i < props.refList.length; i++) {
    if (
      proxy.$parent.$refs[props.refList[i]] &&
      props.refList[i] != props.oref
    ) {
      // console.log("执行");
      proxy.$parent.$refs[props.refList[i]].show = false;
    }
  }
  show.value = !show.value;
};
const titleClick = () => {
  emit("titleClock", "");
};
const collapseBeforeEnter = (el) => {
  // console.log('11, collapseBeforeEnter');
  oldPaddingBottom.value = el.style.paddingBottom;
  oldPaddingTop.value = el.style.paddingTop;
  // 过渡效果开始前设置元素的maxHeight为0，让元素maxHeight有一个初始值
  el.style.paddingTop = "0";
  el.style.paddingBottom = "0";
  el.style.maxHeight = "0";
};
const collapseEnter = (el, done) => {
  // console.log('22, collapseEnter');
  //
  oldOverflow.value = el.style.overflow;
  let elHeight = el.scrollHeight;
  // 过渡效果进入后将元素的maxHeight设置为元素本身的高度，将元素maxHeight设置为auto不会有过渡效果
  if (elHeight > 0) {
    el.style.maxHeight = elHeight + "px";
  } else {
    el.style.maxHeight = "0";
  }
  el.style.paddingTop = oldPaddingTop.value;
  el.style.paddingBottom = oldPaddingBottom.value;

  el.style.overflow = "hidden";
  // done();
  let onTransitionDone = function () {
    done();
    // console.log('enter onTransitionDone');
    el.removeEventListener("transitionend", onTransitionDone, false);
    el.removeEventListener("transitioncancel", onTransitionDone, false);
  };
  // 绑定元素的transition完成事件，在transition完成后立即完成vue的过度动效
  el.addEventListener("transitionend", onTransitionDone, false);
  el.addEventListener("transitioncancel", onTransitionDone, false);
};
const collapseAfterEnter = (el) => {
  // console.log('33, collapseAfterEnter');
  // 过渡效果完成后恢复元素的maxHeight
  el.style.maxHeight = "";
  el.style.overflow = oldOverflow.value;
};

const collapseBeforeLeave = (el) => {
  // console.log('44, collapseBeforeLeave', el.scrollHeight);
  oldPaddingBottom.value = el.style.paddingBottom;
  oldPaddingTop.value = el.style.paddingTop;
  oldOverflow.value = el.style.overflow;

  el.style.maxHeight = el.scrollHeight + "px";
  el.style.overflow = "hidden";
};
const collapseLeave = (el, done) => {
  // console.log('55, collapseLeave', el.scrollHeight);

  if (el.scrollHeight !== 0) {
    el.style.maxHeight = "0";
    el.style.paddingBottom = "0";
    el.style.paddingTop = "0";
  }
  // done();
  let onTransitionDone = function () {
    done();
    // console.log('leave onTransitionDone');
    el.removeEventListener("transitionend", onTransitionDone, false);
    el.removeEventListener("transitioncancel", onTransitionDone, false);
  };
  // 绑定元素的transition完成事件，在transition完成后立即完成vue的过度动效
  el.addEventListener("transitionend", onTransitionDone, false);
  el.addEventListener("transitioncancel", onTransitionDone, false);
};
const collapseAfterLeave = (el) => {
  // console.log('66, collapseAfterLeave');
  el.style.maxHeight = "";
  el.style.overflow = oldOverflow.value;
  el.style.paddingBottom = oldPaddingBottom.value;
  el.style.paddingTop = oldPaddingTop.value;

  oldOverflow.value = oldPaddingBottom.value = oldPaddingTop.value = "";
};
//------------------------ pageLoad -------------------------------------

defineExpose({ show });
</script>

<style lang="scss">
.foldingPanelBox {
  .bottomLine {
    border-bottom: dashed 1px #f6f6f6;
  }
  .titleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .titleConBox {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .iconBox {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      .van-icon {
        rotate: 90deg;
        transition: 0.3s;
      }
    }
    .opIc {
      .van-icon {
        rotate: -90deg;
      }
    }
  }
  .conBox {
    padding: 16px 12px;
  }
}
.collapse-transition-enter-active,
.collapse-transition-leave-active {
  transition: height 0.3s ease-in-out, padding 0.3s ease-in-out,
    max-height 0.3s ease-in-out;
}
</style>
