<!-- 配送单详情弹窗 -->
<template>
  <div>
    <van-action-sheet v-model:show="show" :title="$fanyi('物流信息')">
      <div class="modelPage" v-loading="loading">
        <div class="box" v-if="logisticsData.length">
          <div
            class="boxItem"
            v-for="(item, index) in logisticsData"
            :key="index"
          >
            <div class="itemIconBox">
              <div class="circular" :class="{ active: index == 0 }"></div>
              <div
                class="vertical"
                v-show="index != logisticsData.length - 1"
              ></div>
            </div>
            <div class="conBox">
              <div class="ofont" :class="{ active: index == 0 }">
                {{ item.address }}
              </div>
              <div class="otime">
                {{ item.time }}
              </div>
            </div>
          </div>
        </div>
        <div class="noData" v-else-if="!loading">
          <span>{{ $fanyi("暂无数据") }}</span>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
const show = ref(false);
const logisticsData = ref([]);
const express_no = ref("");
const loading = ref(false);
//--------------------------------methods--------------------------
const open = (data) => {
  show.value = true;
  loading.value = true;
  //   假如没有获取过物流数据或者获取的是不同物流单号的数据就请求接口获取物流数据
  if (data.express_no != express_no.value) {
    express_no.value = data.express_no;
    proxy.$api
      .logisticsTrack({
        express_no: data.express_no,
      })
      .then((res) => {
        loading.value = false;
        ////console.log('logisticsTrack',res)
        if (res.code != 0) return proxy.$message.error(proxy.$fanyi(res.msg));
        //接下来的操作
        logisticsData.value = res.data.address_data;
      });
  } else {
    loading.value = false;
  }
};
//-----------------------load--------------------------------------
defineExpose({ open });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
:deep(.van-action-sheet__header) {
  text-align: center !important;
}
.modelPage {
  min-height: 300px;
}
.box {
  min-height: 300px;
  padding-top: 20px;
  .boxItem {
    padding: 0 30px;
    display: flex;
    .itemIconBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 30px;
      .circular {
        flex: 0 0 19px;
        width: 19px;
        height: 19px;
        background: #d7d7d7;
        border-radius: 50%;
      }
      .circular.active {
        background-color: #b4272b;
      }
      .vertical {
        height: 100%;
        width: 2px;
        flex: 1;
        background-color: #d7d7d7;
      }
    }
    .conBox {
      transform: translateY(-7.5px);
      padding-bottom: 30px;
      .ofont {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 10px;
      }
      .ofont.active {
        color: #b4272b;
      }
      .otime {
        font-size: 20px;
        line-height: 1.5;
        color: #999999;
      }
    }
  }
}
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  span {
    font-size: 40px;
    color: #999999;
    font-weight: 600;
  }
}
</style>
