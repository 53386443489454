<template>
  <div class="pageNN">
    <div class="pageNNTopBox">
      <headBackBar :iCustomizdde="true" :icon="true">
        <template v-slot:left-icon>
          <van-icon name="arrow-left" @click="$router.go(-1)" />
        </template>
        <template #default>
          <span>{{ $fanyi("售后详情") }}</span>
        </template>
      </headBackBar>
      <div class="cardBox afterSalesInformationBox">
        <div class="cardHeadBox">
          {{ $fanyi("售后信息") }}
        </div>
        <div class="line" style="height: 1px"></div>
        <div class="afterSIBCon">
          <div class="infoRow">
            <label>{{ $fanyi("配送单号") }}：</label>
            <span>{{ detailData.porder_sn }}</span>
          </div>
          <div class="infoRow">
            <label>{{ $fanyi("售后编号") }}：</label>
            <span>{{ detailData.after_sale_sn }}</span>
          </div>
          <div class="infoRow">
            <label>{{ $fanyi("售后完成时间") }}：</label>
            <span>{{ detailData.complete_at }}</span>
          </div>
        </div>
        <div class="line" style="height: 1px"></div>
        <div class="afterSalesProducts" v-if="detailData.is_whole_porder == 0">
          <div v-for="(tableItem, tableIndex) in tableData" :key="tableIndex">
            <li>
              <h2>
                <span>{{ $fanyi("订单号") }}：{{ tableItem.order_sn }}</span>
                <span>
                  <img
                    :src="require('@/assets/icon/dingdanfanhao.png')"
                    alt=""
                  />
                  番号：#{{ tableItem.sorting }}
                </span>
              </h2>
              <div class="goodsList">
                <div class="productInformation">
                  <van-image lazy-load class="productImg" :src="tableItem.pic">
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                  <div class="infoBox">
                    <h3>{{ tableItem.goods_title }}</h3>
                    <div class="detailBox">
                      <p
                        v-for="(
                          order_detaildetailitem, order_detaildetailindex
                        ) in tableItem.detail"
                        :key="order_detaildetailindex"
                      >
                        <label>{{ order_detaildetailitem.key }}：</label>
                        <span>{{ order_detaildetailitem.value }}</span>
                      </p>
                    </div>
                    <div class="shipmentQuantityBox">
                      {{ $fanyi("出货数量") }}：{{ tableItem.confirm_num }}
                    </div>
                  </div>
                </div>
                <div class="numRow afterSalesReasonsBox">
                  <h3>{{ $fanyi("售后原因") }}：</h3>
                  <div class="afterSalesReasonsCon">
                    {{ tableItem.client_desc }}
                  </div>
                </div>
                <div class="numRow feedbackIssueImageBox">
                  <h3>{{ $fanyi("反馈问题图片") }}：</h3>
                  <div class="feedbackIssueImageList">
                    <div
                      v-for="(
                        problemitem, problemindex
                      ) in tableItem.client_images"
                      :key="problemindex"
                      @click="$lookImg(problemitem)"
                    >
                      <img
                        :src="problemitem"
                        v-if="problemitem"
                        class="van-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div class="line" style="height: 1px"></div>
          </div>
        </div>
        <div class="afterSalesProducts" v-else>
          <div v-for="(tableItem, tableIndex) in tableData" :key="tableIndex">
            <li>
              <div class="goodsList">
                <div class="numRow afterSalesReasonsBox">
                  <h3>{{ $fanyi("售后原因") }}：</h3>
                  <div class="afterSalesReasonsCon">
                    {{ tableItem.client_desc }}
                  </div>
                </div>
                <div class="numRow feedbackIssueImageBox">
                  <h3>{{ $fanyi("反馈问题图片") }}：</h3>
                  <div class="feedbackIssueImageList">
                    <div
                      v-for="(
                        problemitem, problemindex
                      ) in tableItem.client_images"
                      :key="problemindex"
                      @click="$lookImg(problemitem)"
                    >
                      <van-image :src="problemitem" v-if="problemitem">
                        <template v-slot:loading>
                          <van-loading type="spinner" size="20" />
                        </template>
                      </van-image>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div class="line" style="height: 1px"></div>
          </div>
        </div>
      </div>
      <div class="cardBox specificCIBox">
        <div class="cardHeadBox">
          {{ $fanyi("具体赔款信息") }}
          <button
            @click="
              $fun.routerToPage('/user/churujinList?keywords=' + porderSNBox)
            "
          >
            {{ $fanyi("出入金记录") }}
          </button>
        </div>
        <div class="line" style="height: 1px"></div>
        <div class="costAdjustmentListBox">
          <div class="costAdjustmentOptBox">
            <label> {{ $fanyi("费用调整名义") }}：</label>
            <span v-if="user_bill">{{ user_bill.client_bill_reason }}</span>
          </div>
          <div class="costAdjustmentOptBox">
            <label> {{ $fanyi("具体说明") }}：</label>
            <template v-if="user_bill">
              <span
                v-for="(adjustditem, adjustdindex) in user_bill.adjust_detail"
                :key="adjustdindex"
              >
                <span
                  v-for="(ttadjustditem, ttadjustdindex) in adjustditem"
                  :key="ttadjustdindex"
                >
                  {{ ttadjustditem }};
                </span>
              </span>
            </template>
          </div>
          <div class="costAdjustmentOptBox">
            <label> {{ $fanyi("费用变更") }}：</label>
            <span v-if="user_bill">{{ user_bill.amount }}</span>
          </div>
        </div>
      </div>
      <div class="cardBox shipmentPIBox">
        <quotationInProgressProductList />
      </div>
    </div>
    <div class="pageNNbuttonBox">
      <deliveryAddress ref="deliveryAddressRef" />
      <zhuangxiangxinxi ref="zhuangxiangxinxiRef" />
      <userDeliveryOrderOperation />
    </div>
  </div>
</template>
<script setup>
import headBackBar from "@/components/headGoBack/index.vue";
import zhuangxiangxinxi from "./components/zhuangxiangxinxi.vue";
import userDeliveryOrderOperation from "./components/userDeliveryOrderOperation.vue";
import quotationInProgressProductList from "./components/quotationInProgressProductList.vue";
import deliveryAddress from "./components/deliveryAddress.vue";
import { getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
const { proxy } = getCurrentInstance();
//------------------------ data ----------------------------------------
const detailData = ref({}); //售后单详情接口所有数据
const tableData = ref(); //售后商品信息
const user_bill = ref({}); //具体赔款信息数据
const porderSNBox = ref(); //配送单单号
const deliveryOrderProductData = ref([]); //配送单商品列表数据
const porder_status_name = ref("装箱中"); // 配送单状态;
const porderData = ref({}); //配送单所有数据
const porder_amount = ref({
  send_count: 0, //提出番数
  send_num: 0, //提出商品数量
  international_logistics_fee: 0, //国际运费合计（人民币）
  international_logistics_fee_jpy: 0, //国际运费合计（日元）
  other_fee: 0, //箱子的其他费用合计（人民币）
  other_fee_jpy: 0, //箱子的其他费用合计（日元）
  sum_amount: 0, //总费用合计（人民币）
  sum_amount_jpy: 0, //总费用合计（日元）
  discounts_amount_jpy: 0, //优惠金额合计（日元）
  discounts_detail: [], //优惠明细
  pay_amount_jpy: 0, //实际支付金额（日元）
}); //配送单信息
//------------------------ methods -------------------------------------
const getData = () => {
  proxy.$api
    .aftersaledetail({
      after_sale_sn: proxy.$route.query.after_sale_sn,
    })
    .then((res) => {
      if (res.code !== 0) {
        Toast.fail(proxy.$fanyi(res.msg));
      }
      detailData.value = res.data;
      tableData.value = res.data.after_sale_detail;
      user_bill.value = res.data.user_bill;
      porderSNBox.value = res.data.porder_sn;
      proxy.$api
        .storageDeliverDetail({
          porder_sn: res.data.porder_sn,
        })
        .then((delires) => {
          if (delires.code !== 0) {
            Toast.fail(proxy.$fanyi(delires.msg));
          }
          // 2023/5/11 原型图上说已取消状态和装箱中状态显示的东西一样
          if (delires.data.porder_status_name == "已取消") {
            delires.data.porder_status_name = "装箱中";
          }
          porder_amount.value = delires.data.porder_amount;
          porder_status_name.value = delires.data.porder_status_name;
          deliveryOrderProductData.value = delires.data.porder_detail;
          porderData.value = delires.data;
          porderData.value.receiver_address = {
            name: delires.data.address.receiver.contacts,
            company: delires.data.address.receiver.company,
            address: delires.data.address.receiver.address,
            zip: delires.data.address.receiver.zip_code,
            mobile: delires.data.address.receiver.mobile,
            tel: delires.data.address.receiver.tel,
            name_rome: delires.data.address.receiver.contacts_roma,
            address_rome: delires.data.address.receiver.address_roma,
            corporate_name: delires.data.address.receiver.tax_no,
            account: delires.data.address.receiver.bank_no,
            standard_code: delires.data.address.receiver.standard_no,
          };
          porderData.value.importer_address = {
            name: delires.data.address.importer.contacts,
            company: delires.data.address.importer.company,
            address: delires.data.address.importer.address,
            zip: delires.data.address.importer.zip_code,
            mobile: delires.data.address.importer.mobile,
            tel: delires.data.address.importer.tel,
            name_rome: delires.data.address.importer.contacts_roma,
            address_rome: delires.data.address.importer.address_roma,
            corporate_name: delires.data.address.importer.tax_no,
            account: delires.data.address.importer.bank_no,
            standard_code: delires.data.address.importer.standard_no,
          };
          porderData.value.client_remark =
            typeof delires.data.porder_client_remark == "string"
              ? delires.data.porder_client_remark
              : "";
        });
    });
};
// 打开装箱信息弹窗
const openBoxDetail = () => {
  proxy.$refs.zhuangxiangxinxiRef.open(porderData.value.porder_box);
};
// 打开配送单地址编辑弹窗
const opendeliveryAddress = () => {
  proxy.$refs.deliveryAddressRef.open(porderData.value);
};
//------------------------ pageLoad -------------------------------------
getData();
defineExpose({
  deliveryOrderProductData,
  porder_status_name,
  porder_amount,
  porderData,
  porderSNBox,
  openBoxDetail,
  opendeliveryAddress,
});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
* {
  font-size: 24px;
  line-height: 1.5;
}
.pageNN {
  background-color: #f7f7f7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .pageNNTopBox {
    padding-bottom: 63px;
    .cardBox {
      margin: 20px 30px;
      width: 690px;
      background: #ffffff;
      border-radius: 6px;
      .cardHeadBox {
        height: 82px;
        display: flex;
        align-items: center;
        font-size: 28px;
        font-weight: 500;
        padding: 0 30px;
        button {
          margin-left: auto;
          height: 42px;
          background: #b4272d;
          border-radius: 8px;
          padding: 0 20px;
          font-size: 20px;
          font-weight: 500;
          color: white;
        }
      }
      .line {
        background: #f6f6f6;
      }
    }
    .cardBox.afterSalesInformationBox {
      .afterSIBCon {
        padding: 1px;
        .infoRow {
          display: flex;
          align-items: center;
          margin: 20px 30px;
          font-size: 24px;
          lebel {
            display: inline-block;
            line-height: 36px;
            font-size: 24px;
            color: #999999;
          }
          span {
            display: inline-block;
            line-height: 36px;
            font-size: 24px;
          }
        }
      }
      .afterSalesProducts {
        li {
          background-color: white;
          list-style: none;
          padding: 0 30px 30px;
          h2 {
            height: 86px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .van-icon-arrow-down {
              margin-left: auto;
              transition: 0.3s;
              font-size: 30px;
            }
            .van-icon-arrow-down.Collapse {
              rotate: -180deg;
            }
            span {
              font-size: 24px;
              font-weight: 500;
              display: flex;
              align-items: center;
              img {
                margin-right: 5px;
                width: 26px;
                height: 26px;
              }
            }
          }
          // 订单内商品
          .goodsList {
            padding-bottom: 1px;
            margin-bottom: 30px;
            border-bottom: dashed 1px #dfdfdf;
            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }
            .outfit {
              display: flex;
              align-items: center;
              height: 42px;

              margin-bottom: 20px;
              span {
                font-size: 28px;
              }
            }
            .productInformation {
              display: flex;
              margin-bottom: 20px;
              .productImg {
                $size: 120px;
                flex: 0 0 $size;
                width: $size;
                height: $size;
                border-radius: 6px;
                overflow: hidden;
                margin-right: 20px;
              }
              .infoBox {
                h3 {
                  font-size: 28px;
                  line-height: 42px;
                  margin-bottom: 5px;
                  text-overflow: -o-ellipsis-lastline;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
                .detailBox {
                  text-overflow: -o-ellipsis-lastline;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  line-height: 34px;
                  -webkit-box-orient: vertical;
                  margin-bottom: 10px;
                  p {
                    line-height: 34px;
                    label {
                      color: #999;
                      font-size: 24px;
                      line-height: 34px;
                    }
                    span {
                      font-size: 24px;
                      line-height: 34px;
                    }
                  }
                }
                .shipmentQuantityBox {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 24px;
                }
              }
            }
            .numRow {
              margin-bottom: 20px;
              &:last-child {
                margin-bottom: 0;
              }
              h3 {
                font-size: 24px;
                font-weight: 400;
                color: #999999;
                line-height: 36px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
              }
            }
            .numRow.afterSalesReasonsBox {
              .afterSalesReasonsCon {
                background: #f9f9f9;
                border: 1px solid #dfdfdf;
                border-radius: 6px;
                padding: 15px;
                font-size: 22px;
                font-weight: 400;
              }
            }
            .numRow.feedbackIssueImageBox {
              .feedbackIssueImageList {
                display: flex;
                .van-image {
                  width: 80px;
                  height: 80px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
    .cardBox.specificCIBox {
      padding-bottom: 30px;
      .costAdjustmentListBox {
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        padding: 20px;
        margin: 20px 30px;
        &:last-child {
          margin-bottom: 0;
        }
        .costAdjustmentOptBox {
          display: flex;
          line-height: 36px;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          label {
            white-space: nowrap;
            color: #999;
          }
        }
      }
    }
    .cardBox.shipmentPIBox {
    }
  }

  .pageNNbuttonBox {
    position: sticky;
    bottom: 0;
    margin-top: auto;
  }
}
</style>
