<!-- 显示商品属性弹窗 仓库模块使用 -->
<!-- 接收数据格式 -->
<!-- {
    goods_title:
      "12色德绒艾窈蜜仙女边木耳边打底衫秋冬韩版女上衣百搭长袖t恤",
    pic: "https://cbu01.alicdn.com/img/ibank/O1CN01lHJxjK1UV7XBqQ0in_!!3608782522-0-cib.jpg",
    detail: [
      {
        key: "颜色",
        value: "黑色（厚款）仙女边",
      },
      {
        key: "尺码",
        value: "均码",
      },
    ],
  } -->
<template>
  <div>
    <van-action-sheet v-model:show="show" :title="$fanyi('商品详情')">
      <div class="modelPage">
        <div class="goodsInfo">
          <van-image
            class="goodsImg"
            :src="detailData.pic"
            @click="$lookImg(detailData.pic)"
            lazy-load
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <div class="goodsTitle">
            {{ detailData.goods_title }}
          </div>
        </div>
        <ul class="detailList">
          <li
            v-for="(detailItem, detailIndex) in detailData.detail"
            :key="detailIndex"
          >
            <span class="grayFont">{{ detailItem.key }}:</span>
            <span>{{ detailItem.value }}</span>
          </li>
        </ul>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { getCurrentInstance, ref } from "vue";
export default {
  setup(props) {
    const { proxy } = getCurrentInstance();
    const show = ref(false);
    const detailData = ref({});
    const open = (data) => {
      show.value = true;
      detailData.value = data;
    };
    return {
      show,
      detailData,
      open,
    };
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
:deep(.van-action-sheet__header) {
  text-align: center !important;
}
.modelPage {
  margin: 0 30px;
  .goodsInfo {
    display: flex;
    padding-bottom: 30px;
    border-bottom: solid 1px #dfdfdf;
    .goodsImg {
      $size: 120px;
      flex: 0 0 $size;
      width: $size;
      height: $size;
      margin-right: 20px;
    }
    .goodsTitle {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 28px;
      line-height: 42px;
    }
  }
  .detailList {
    min-height: 369px;
    li {
      margin: 30px 0;

      display: flex;
      span {
        font-size: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .grayFont {
        display: inline-block;
        flex: 0 0 140px;
        color: #999999;
      }
    }
  }
}
</style>
